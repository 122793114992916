<template>
  <div>
    <!-- <div class="d-flex justify-content-start align-items-center my-10">
      Toplam {{ totalItems }} müşteri bulundu.
    </div> -->
    <div
      class="d-flex align-items-center justify-content-between search-list-area"
    >
      <div class="d-flex align-items-center search-input mb-4">
        <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
        <select v-model="list_length" class="form-control ml-2">
          <option value="" selected>Seçiniz</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </div>
        <b-button variant="outline-primary" class="d-flex d-md-inline-block mt-0 w-md-auto w-100 mb-6"  v-b-toggle.sidebar-right>Filtrele</b-button>
    </div>

    <div class="">
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Varlık Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.id="{ item }">
          <router-link :to="`find/detail/${item.id}/`">
            <button
            class="btn btn-sm btn-light"
          >
            <i class="flaticon2-next text-dark p-0"></i>
          </button>
          </router-link>
        </template>
        <template v-slot:item.kalanyuzde="{item}">
          {{ item.remainingPercentage }} %
        </template>
        <template v-slot:item.price="{item}">
          {{ item.price }} ₺
        </template>
        <template v-slot:item.saleType="{item}">
          <div v-if="item.saleType === 'percent'">Parçalı</div>
          <div v-else>Miktar</div>

        </template>
        <template v-slot:item.start="{item}">
          {{ formatDate(item.startOf) }}
        </template>
        <template v-slot:item.end="{item}">
          {{ formatDate(item.endOf) }}
        </template>
        <template v-slot:item.status="{item}">
           <b-badge variant="success" v-if="item.status === 'started'">Başladı</b-badge>
           <b-badge variant="danger" v-else>İptal Edildi</b-badge>

        </template>
       
        <template v-slot:item.action="{ item }" class="p-0">
          <div class="d-flex px-4">
            <router-link
              :to="`properties/detail`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                class="ml-auto"
                :href="href"
                @click="navigate"
                ><i class="flaticon-home-1 text-dark p-0"></i
              ></b-button>
            </router-link>
            <router-link
              :to="`/transfer/sales/detail/1`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                class="ml-auto"
                :href="href"
                @click="navigate"
                ><i class="flaticon2-photo-camera text-primary p-0"></i
              ></b-button>
            </router-link>

            <router-link
              :to="`/declaration/detail`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                class="ml-auto"
                :href="href"
                @click="navigate"
                ><i class="flaticon2-sheet text-primary p-0"></i
              ></b-button>
            </router-link>

            <router-link
              :to="`/transfer/edit`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                class="ml-auto"
                :href="href"
                @click="navigate"
                ><i class="flaticon2-reload text-dark p-0"></i
              ></b-button>
            </router-link>
          </div>
        </template>
      </List>
      <!-- Create and Actions Popups -->
    </div>
    <div class="notes-sidebar top-full">
        <b-sidebar id="sidebar-right" right>
          <div class="card dveb-gray-card aside-gray-card tab-card" style="overflow-y:auto">
            <div class="card-header">
              FİLTRELE
              
            </div>
            <b-tabs content-class="mt-3">
            <b-tab content-class="tab-content" title="Filtre" active>
                <div class="form-group">
                    <label for="sahip">Sahibi</label>
                    <select name="" id="sahip" class="form-control" >
                        <option value="" selected>Seçiniz</option>
                        <option value="">Şekerbank A.Ş</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="izle">İzlenecek</label>
                    <select name="" id="izle" class="form-control" >
                        <option value="" selected>Seçiniz</option>
                        <option value="">Evet</option>
                        <option value="">Hayır</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="durum">Durum</label>
                    <select name="" id="durum" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">1</option>
                        <option value="">2</option>
                        <option value="">3</option>
                    </select>
                    
                </div>
                <div class="form-group">
                    <label for="il">İl</label>
                    <select name="" id="il" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">1</option>
                        <option value="">2</option>
                        <option value="">3</option>
                    </select>
                    
                </div>
                <div class="form-group">
                    <label for="ilce">İlçe</label>
                    <select name="" id="ilce" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">1</option>
                        <option value="">2</option>
                        <option value="">3</option>
                    </select> 
                </div>
                <div class="form-group">
                    <label for="anahat">Anahtar Durumu</label>
                    <select name="" id="anahat" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">Var</option>
                        <option value="">Yok</option>
                        <option value="">3</option>
                    </select> 
                </div>
                <div class="form-group">
                    <label for="anahat" class="form-label">İşgalci Durumu</label>
                    <select name="" id="anahat" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">Var</option>
                        <option value="">Yok</option>
                        <option value="">3</option>
                    </select> 
                </div>
                <div class="form-group">
                    <label for="anahat">Ada</label>
                    <input type="text" class="form-control">
                </div>
                <div class="form-group">
                    <label for="anahat">Parsel</label>
                    <input type="text" class="form-control">
                </div>
                <div class="row">
                    <div class="col-md-6 col-12 mt-4">
                        <b-button variant="light" class="w-100" >
                            Temizle
                        </b-button>
                    </div>
                    <div class="col-md-6 col-12 mt-4">
                        <b-button variant="light" class="w-100">
                            Uygula
                        </b-button>
                    </div>
                    <div class="col-12 mt-4">
                        <b-button variant="light" class="w-100">
                            Bu Filtreyi Kaydet
                        </b-button>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Hızlı Ara">
                <div
                class="card mt-4 dveb-card-white"
                style="border-radius: 0 !important"
              >
                <div>
                  <h4> Filtre Örnek </h4>
                  <hr>
                  <div class="row ml-auto">
                    <b-button variant="success" class="mr-4" >
                      Uygula
                    </b-button>
                    <b-button variant="danger" class="" @click="openDeleteHandler(key)">
                      Sil
                    </b-button>
                  </div>
                </div>
            </div>
            </b-tab>
          </b-tabs>

          </div>
        </b-sidebar>
    </div>
    <DeleteOperation
    />
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 990px) {
  .search-list-area {
    flex-direction: column;
    .search-input {
      width: 100%;
      * {
        width: 100%;
      }
    }
  }
  .search-buttons {
    flex-direction: column;
  }
}

.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      padding: 0 !important;

      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
}
.tapu-text {
  span {
    background-color: $dveb-divider;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
  }
}
.circle{
  min-width: 25px !important;
  min-height: 25px !important;
  width: 25px !important;
  height: 25px !important;
  font-size: 10px;
  &.circle-0{
    background-color: $dveb-badge-pink ;
  }
  &.circle-1{
    background-color: $dveb-badge-blue;
  }
  &.circle-2{
    background-color:$dveb-badge-green ;
  }
  &.circle-plus{
    background-color: $dveb-badge-orange;
  }
  
  
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment"
export default {
  name: "customerList",
  components: {
    Steps: () => import("@/components/global/StepBar"),
  },
  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      badgesEnum: ["green", "yellow", "red"],
      stepNames : ["Hazırlık","Satış","Devir"],
      header: [
        { text: "", value: "id", size: "50px" },
        { text: "Varlık", sortable:true,value: "title" },
        {text:"Satış Tipi",value:"saleType"},
        { text: "Kalan Yüzde", sortable:true,value: "kalanyuzde" },
        { text: "Fiyat", sortable:true,value: "price" },
        { text: "Başlangıç Tarihi", sortable:true,value: "start" },
        { text: "Bitiş Tarihi", sortable:true,value: "end" },
        {text:"Varlık Tipi", value:"type"},
        { text: "Durum", sortable:true , value: "status" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      filterList:[],
      list_length:10
    };
  },

  created() {
    this.search = this.$route.query.search
    this.getList();

  },
  methods: {
    getList() {
      this.loading = true;
      
      this.totalItems = this.items.length;

      this.pagination = {
        current_page: 1,
        total_pages: Math.ceil(this.items?.length/this.list_length),
        total_items: this.items.length,
        list_length: this.list_length
      };
      

      let queryLimit = this.list_length ? this.list_length : 10;
      let query = `property/getAllProperties`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
            this.items = res.propertiesAgain;
            this.totalItems = res.propertiesAgain.count;

            this.pagination = {
              current_page: res.page,
              total_pages: res.pageCount,
              total_items: res.count,
            };
          
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(key) {
      this.$bvModal.show('modal-deleteOperation');
      let index = this.notes.indexOf(key)
      this.notes.splice(index,1)
    },
    formatDate(date){
      return moment( new Date(date)).format("LLL")
    }

    
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
    list_length:function(){
      this.getList()
    },
    

  },
  mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Varlıklar / Bul", route: "/properties/find" },
      ]);
    },
};
</script>
